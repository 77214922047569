import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [bg, setBg] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setBg(true);
      } else {
        setBg(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`fixed z-50 w-full text-white transition-colors duration-300 ${
        bg ? "bg-blue-900" : "bg-transparent"
      }`}
    >
      <nav className="container mx-auto flex items-center justify-between py-4 px-6">
        <div>
          <h1 className="text-3xl font-bold">BYT3</h1>
        </div>
        <div className="hidden md:flex space-x-4 font-semibold">
          <a
            href="/#"
            className="hover:text-gray-200 transition duration-300 ease-in-out"
          >
            Home
          </a>
          <a
            href="/#about"
            className="hover:text-gray-200 transition duration-300 ease-in-out"
          >
            What is BYT3
          </a>
          <a
            href="/#how-to-buy"
            className="hover:text-gray-200 transition duration-300 ease-in-out"
          >
            How to buy
          </a>
        </div>
        <div className="md:hidden">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
      </nav>
      {menuOpen && (
        <div className="md:hidden bg-blue-900 px-6 py-4">
          <a
            href="/#"
            className="block py-2 hover:text-gray-200 transition duration-300 ease-in-out"
            onClick={() => setMenuOpen(false)}
          >
            Home
          </a>
          <a
            href="/#about"
            className="block py-2 hover:text-gray-200 transition duration-300 ease-in-out"
            onClick={() => setMenuOpen(false)}
          >
            What is BYT3
          </a>
          <a
            href="/#how-to-buy"
            className="block py-2 hover:text-gray-200 transition duration-300 ease-in-out"
            onClick={() => setMenuOpen(false)}
          >
            How to buy
          </a>
        </div>
      )}
    </div>
  );
}
