import { Twitter, Instagram, Send, Mail } from "lucide-react";

export default function HeroSection() {
  return (
    <div
      id="#"
      style={{
        backgroundImage: `url('/images/bg2.png')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="min-h-screen bg-blue-900 flex flex-col justify-center items-center text-white"
    >
      <div className="text-center mb-8">
        <h1 className="text-5xl md:text-8xl font-bold mb-4">$BYT3</h1>
        <p className="text-lg md:text-xl mb-8">
          Empowering Positive Influencers in the Crypto World
        </p>
        <a
          href="#about"
          className="bg-white text-blue-900 py-3 px-6 rounded-full font-semibold text-lg hover:bg-blue-100 transition duration-300 ease-in-out"
        >
          Get Started
        </a>
      </div>
      <div className="flex space-x-6">
        <a
          href="https://x.com/Byt3____"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitter className="text-xl md:text-3xl hover:text-gray-200 transition duration-300 ease-in-out" />
        </a>
        <a
          href="https://www.instagram.com/byt3___?igsh=MTEzbTZoaXN6eGo1Zw=="
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram className="text-xl md:text-3xl hover:text-gray-200 transition duration-300 ease-in-out" />
        </a>
        <a
          href="https://t.me/byt3crypto"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Send className="text-xl md:text-3xl hover:text-gray-200 transition duration-300 ease-in-out" />
        </a>
      </div>
    </div>
  );
}
