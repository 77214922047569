import { Twitter, Instagram, Mail } from "lucide-react";

export default function Footer() {
  return (
    <div className="bg-blue-900 p-1 text-white w-full">
      <div className="text-center m-1">
        {/* <hr /> */}
        <h1>© 2024 | Byt3 . All rights reserved.</h1>
      </div>
    </div>
  );
}

// export default function Footer() {
//   return (
//     <div className="bg-blue-900 p-8 text-white">
//       <div className="container mx-auto flex flex-col md:flex-row flex-wrap justify-between items-start">
//         <div className="mb-6 md:mb-0 w-full md:w-1/3 text-center md:text-left">
//           <h1 className="text-3xl font-bold mb-2">BYT3</h1>
//           <p>
//             Byt3 coin is a revolutionary cryptocurrency designed to empower
//             positive influencers and facilitate secure, transparent
//             transactions.
//           </p>
//         </div>
//         <div className="mb-6 md:mb-0 w-full md:w-1/3 text-center md:text-left">
//           <ul>
//             <li>
//               <a href="#about" className="hover:underline">
//                 What is BYT3
//               </a>
//             </li>
//             <li>
//               <a href="#how-to-buy" className="hover:underline">
//                 How to Buy
//               </a>
//             </li>
//           </ul>
//         </div>
//         <div className="w-full md:w-1/3 flex flex-col space-y-4 text-center md:text-left">
//           <div>
//             <h1 className="text-xl font-semibold mb-2">Join our Newsletter</h1>
//             <p className="mb-2">
//               Get the latest updates about $Byt3 directly in your inbox.
//             </p>
//             <input
//               type="email"
//               placeholder="Enter your email"
//               className="px-4 py-2 rounded bg-white text-blue-900 focus:outline-none w-full"
//             />
//           </div>
//           <div className="flex justify-center md:justify-start space-x-4 mt-4">
//             <a href="https://www.instagram.com" aria-label="Instagram">
//               <Instagram className="text-white hover:text-gray-300 transition duration-300 ease-in-out" />
//             </a>
//             <a href="https://www.twitter.com" aria-label="Twitter">
//               <Twitter className="text-white hover:text-gray-300 transition duration-300 ease-in-out" />
//             </a>
//             <a href="mailto:info@byt3.com" aria-label="Email">
//               <Mail className="text-white hover:text-gray-300 transition duration-300 ease-in-out" />
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
