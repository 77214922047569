import React from "react";

import { useRef, useState } from "react";

const FaqsCard = (props) => {
  const answerElRef = useRef();
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState("0px");
  const { faqsList, idx } = props;

  const handleOpenAnswer = () => {
    const answerElH = answerElRef.current.childNodes[0].offsetHeight;
    setState(!state);
    setAnswerH(`${answerElH + 20}px`);
  };

  return (
    <div
      className="space-y-3  overflow-hidden border-b "
      key={idx}
      onClick={handleOpenAnswer}
    >
      <h4 className="cursor-pointer pb-5 flex items-center justify-between text-xl  text-gray-900  font-medium">
        {faqsList.q}
        {state ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M20 12H4"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
        )}
      </h4>
      <div
        ref={answerElRef}
        className="duration-300"
        style={state ? { height: answerH } : { height: "0px" }}
      >
        <div>
          <p className="text-gray-700 text-lg">{faqsList.a}</p>
        </div>
      </div>
    </div>
  );
};

export default function QnA() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  };

  const faqsList = [
    {
      q: "How does Byt3 support positive influencers?",
      a: "Byt3 rewards positive influencers on social media platforms, such as TikTok, who reach significant milestones like one million followers. This is done through donations and campaign marketing within the ERC20 community, promoting a culture of positive influence.",
    },
    {
      q: "What makes Byt3 different from other cryptocurrencies?",
      a: "Byt3 is unique in its mission to support and reward positive influencers, alongside offering low transaction fees, enhanced privacy, and scalability for high transaction throughput. Its focus on community and social impact sets it apart from other cryptocurrencies.",
    },
    {
      q: "Can I use Byt3 for everyday transactions?",
      a: "Yes, Byt3 is designed to be used for everyday transactions, offering low fees and quick transaction times, making it practical for daily use.",
    },
  ];

  return (
    <div className="text-white p-4 flex min-h-screen items-center justify-around w-full">
      <section className="w-full h-full">
        <div className="flex flex-col md:flex-row justify-between items-center p-4">
          <div className="w-full md:hidden p-4 m-10 md:w-1/2 flex justify-center md:justify-end">
            <img
              src="/images/icon.png"
              alt="Byt3 Logo"
              className="max-w-xs md:max-w-md"
            />
          </div>
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-2xl md:text-4xl font-bold text-blue-800">
              Frequently Asked Questions
            </h1>
            <div className="pt-6">
              {faqsList.map((item, idx) => (
                <FaqsCard key={idx} idx={idx} faqsList={item} />
              ))}
            </div>
          </div>
          <div className="w-full hidden md:w-1/2 md:flex justify-center md:justify-end">
            <img
              src="/images/icon.png"
              alt="Byt3 Logo"
              className="max-w-xs md:max-w-md"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
