import AboutUs from "../components/AboutUs";
import HowtoBuy from "../components/Features";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import Navbar from "../components/Navbar";
import QnA from "../components/QnA";
export default function Home() {
  return (
    <div className=" text-white min-h-screen">
      <Navbar />
      <div className="flex flex-col ">
        <HeroSection />
        <AboutUs  />
        <HowtoBuy />
        <QnA />j
      </div>
      <Footer />
    </div>
  );
}
