export default function HowtoBuy() {
  return (
    <div id="how-to-buy" className="bg-blue-900 py-16">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center text-white mb-8">
          <h1 className="text-3xl md:text-4xl font-bold">HOW TO BUY $BYT3</h1>
          <h2 className="mt-4 text-sm md:text-base">
            Contract address:
            <span className="block md:inline border rounded-xl mt-2 md:mt-0 p-1 text-gray-400 font-semibold break-all md:break-normal">
              0x5a9e262845A8bc87Dc8CaE1af11891D27b053FC0
            </span>
          </h2>
        </div>

        <div className="bg-blue-800 rounded-lg shadow-md p-6">
          <h3 className="text-xl font-semibold mb-4 text-white">
            Steps to Buy $BYT3
          </h3>
          <ol className="list-decimal list-inside text-gray-200 mb-4 space-y-2">
            <li>
              Be in possession of BNB or any other relevant token on the Binance
              Smart Chain.
            </li>
            <li>Copy the contract address of the $BYT3 token.</li>
            <li>
              Go to PancakeSwap and swap any relevant token on the Binance Smart
              Chain for $BYT3.
            </li>
          </ol>
          <div className="text-center mb-4">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://pancakeswap.finance/swap/limit?outputCurrency=0x5a9e262845A8bc87Dc8CaE1af11891D27b053FC0&inputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
              className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Buy $BYT3
            </a>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.dextools.io/app/en/bnb/pair-explorer/0xc2cd01226dc4c45daa245aeee9f07471714aac3c?t=1720551666414"
          className="inline-block underline text-white font-bold "
        >
          View BYT3 on Dextools
        </a>
      </div>
    </div>
  );
}
