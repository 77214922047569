export default function AboutUs() {
  return (
    <div
      className="text-blue-800 py-16 px-8 min-h-screen relative  "
      id="about"
    >
      <div className="container mx-auto relative">
        <div className="flex flex-wrap justify-between items-center">
          <div className="w-full md:w-1/2 mb-8 md:mb-0 flex justify-center md:justify-start relative z-10">
            <img
              src="./logo.png"
              alt="Byt3 Logo"
              className="bg-white p-4 rounded-lg shadow-lg w-full max-w-xs md:max-w-none"
            />
          </div>
          <div className="w-full md:w-1/2 relative">
            <div
              className="absolute inset-0 bg-cover bg-center opacity-50 md:opacity-25"
              style={{
                backgroundImage: `url('/images/bitcoins.webp')`,
              }}
            ></div>
            <div className="relative z-10 p-8 bg-white bg-opacity-75 rounded-lg">
              <h1 className="text-4xl font-bold mb-4 underline text-blue-900">
                WHAT IS BYT3
              </h1>
              <p className="text-lg mb-4">
                Byt3 is a groundbreaking cryptocurrency designed to transform
                how we think about digital transactions and value storage. It
                leverages advanced blockchain technology to provide a secure,
                transparent, and decentralized platform for all its users.
              </p>
              <p className="text-lg mb-4">
                Our mission is to reward influential personas on social media
                platforms, like TikTok, who achieve significant milestones, such
                as reaching one million followers. Byt3 not only offers users a
                secure way to store value but also facilitates low-fee
                transactions, enabling them to earn more money through its use.
              </p>
              <p className="text-lg">
                Byt3 is more than just a cryptocurrency; it’s a
                community-focused initiative aimed at promoting positive
                influence. Through donations and campaign marketing within the
                ERC20 community, Byt3 supports and motivates influencers to
                continue spreading positivity and good deeds.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
